import Vue from "vue"

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import i18n from './plugins/i18n'
import http from './plugins/http'
import store from './plugins/store'
import router from './plugins/router'
import auth from './plugins/auth'
import vuetify from './plugins/vuetify'
import VueObserveVisibility from "vue-observe-visibility";
import {Tree} from "element-ui"
import App from "./App.vue"
import UUID from 'vue-uuid';
import FloatingVue from 'floating-vue'
import PageLayout from "@/components/Leentech/PageLayout.vue";
import ResizableTable from "@/components/Leentech/ResizableTable.vue";
import VueScrollTo from "vue-scrollto";
import './plugins/tinymce'
import './plugins/pusher'
import './plugins/prototype'
import './plugins/vee-validate'
import './registerServiceWorker'
import './plugins/firebase'
import './plugins/toastr'

Vue.config.productionTip = false

Vue.use(FloatingVue)
Vue.use(VueObserveVisibility);
Vue.use(UUID);

moment.locale(i18n.locale)
moment.defaultFormat = "YYYY-MM-DD HH:mm:ss"
Vue.use(VueMoment, {
    moment,
})

Vue.component(ResizableTable.name, ResizableTable)
Vue.component(Tree.name, Tree)
Vue.component(PageLayout.name, PageLayout)

Vue.prototype.$yandex = {
    apiKey: process.env.VUE_APP_YANDEX_MAP_KEY,
    lang: "ru_RU",
    coordorder: "latlong",
    version: "2.1"
}

Vue.prototype.$google = {
    maps_key: process.env.VUE_APP_GOOGLE_MAP_KEY
}

Vue.prototype.$pusherServer = process.env.VUE_APP_PUSH_SERVER

Vue.use(VueScrollTo, {
    container: 'body',
    lazy: true,
    easing: 'ease',
    offset: -100,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
})


new Vue({
    i18n: i18n,
    http: http,
    store: store,
    router: router,
    config: auth,
    vuetify: vuetify,
    VueScrollTo: VueScrollTo,
    render: h => h(App)
}).$mount("#app")
