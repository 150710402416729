<template>
    <v-navigation-drawer v-if="false" v-model="drawerShow" mobile-breakpoint="960" height="100%" app>
        <v-list class="py-0 company-logo" color="primary" style="border-radius:0;height:64px;">
            <v-list-item>
                <v-list-item-content>
                    <v-img src="/img/logo.png" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn small icon @click.stop="navToggle" class="mx-0">
                        <v-icon class="white--text">mdi-close</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider class="my-0"></v-divider>
        <scrollbar ref="scrollbar" :swicher="!$vuetify.breakpoint.smAndDown"
                   :settings="settingsScrollbar" class="inner-scrollbar">
            <v-list class="py-0" dense v-for="(group, index) in items" :key="index">
                <template v-for="item in group">
                    <v-subheader v-if="item.header" v-show="item.visible">{{ $t(item.title) }}</v-subheader>
                    <v-list-group v-else-if="!!item.items" v-show="item.visible" :prepend-icon="item.icon" no-action
                                  :key="item.title" v-model="item.active">
                        <template v-slot:activator>
                            <v-list-item-content class="nav-bar-content">
                                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="subItem in item.items" :key="subItem.title" v-show="subItem.visible"
                                     @click.native="subItem.action ? subItem.action() : false" :to="subItem.to" ripple
                                     :exact="subItem.exact !== undefined ? subItem.exact : true">
                            <v-list-item-icon>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pl-2 nav-bar-content">
                                <v-list-item-title>{{ $t(subItem.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else-if="!item.header" v-show="item.visible"
                                 @click.native="item.action ? item.action() : false"
                                 href="javascript:void(0)" :to="item.to" ripple
                                 :exact="item.exact !== undefined ? item.exact : true" :key="item.title">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="nav-bar-content">
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </scrollbar>
    </v-navigation-drawer>
</template>
<script>

import Scrollbar from './Scrollbar'

export default {
    name: "AppSidebar",
    components: {
        Scrollbar,
    },
    inject: ['forceRerender'],
    props: {
        drawer: Boolean
    },
    data() {
        return {
            isMini: null,
            items: [],
            settingsScrollbar: {
                suppressScrollX: true,
            },
        }
    },
    computed: {
        drawerShow: {
            get: function () {
                return this.drawer
            },
            set: function (state) {
                if (state !== this.drawer) {
                    this.navToggle()
                }
            }
        },
        routePath() {
            return this.$route.path
        },
    },
    watch: {
        routePath(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.navigation()
            }
        },
    },
    mounted() {
        this.navigation()
    },
    methods: {
        navToggle() {
            this.$emit("nav-toggle")
        },
        navigation() {
            this.items = [
                [
                    {
                        title: 'menu_general',
                        header: true,
                        visible: this.$auth.check(),
                    },
                    {
                        title: 'menu_dashboard',
                        icon: "mdi-view-dashboard mdi-18px",
                        to: {name: "dashboard"},
                        action: this.forceRerender,
                        visible: this.$auth.check(),
                        exact: false
                    },

                    {
                        title: 'menu_billing',
                        header: true,
                        visible: this.can(['administrator', 'manager', 'accountant', 'collector', 'storekeeper', 'pp_manager', 'moderator']),
                    },
                    {
                        title: 'menu_uved',
                        icon: "mdi-card-account-details-outline mdi-18px",
                        to: {name: "uved"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager', 'accountant']),
                        exact: false
                    },
                    {
                        title: 'menu_payments',
                        icon: "mdi-cash mdi-18px",
                        to: {name: "invoice"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager', 'accountant']),
                        exact: false
                    },

                    {
                        title: 'menu_my_topics',
                        icon: "mdi-clipboard-text-play-outline mdi-18px",
                        to: {name: "my_topic"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager', 'accountant']),
                        exact: false
                    },
                    {
                        title: 'menu_topics',
                        icon: "mdi-clipboard-text-play-outline mdi-18px",
                        to: {name: "topics"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager', 'accountant']),
                        exact: false
                    },
                    {
                        title: 'menu_deals',
                        icon: "mdi-briefcase-variant-outline mdi-18px",
                        to: {name: "deal"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_tasks',
                        icon: "mdi-file-document-outline mdi-18px",
                        to: {name: "task"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_tracking',
                        icon: "mdi-train-variant mdi-18px",
                        to: {name: "tracking"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_calendar',
                        icon: "mdi-calendar mdi-18px",
                        to: {name: "calendar"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_notification',
                        icon: "mdi-notification-clear-all mdi-18px",
                        to: {name: "notifications"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager','accountant']),
                        exact: false
                    },

                    {
                        title: 'menu_administration',
                        header: true,
                        visible: this.can(['administrator','manager','accountant']),
                    },
                    {
                        title: 'menu_staff',
                        icon: 'mdi-account-supervisor mdi-18px',
                        visible: this.can(['administrator','manager','accountant']),
                        exact: false,
                        active: ['/administrator', '/employee_position', '/vacation'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_staff',
                                icon: "mdi-account-tie mdi-18px",
                                to: {name: 'administrator'},
                                action: this.forceRerender,
                                visible: this.can(['administrator']),
                                exact: false
                            },
                            {
                                title: 'menu_vacation',
                                icon: "mdi-airplane mdi-18px",
                                to: {name: 'vacation'},
                                action: this.forceRerender,
                                visible: this.can(['administrator']),
                                exact: false
                            },
                            {
                                title: 'menu_employee_position',
                                icon: "mdi-account-hard-hat mdi-18px",
                                to: {name: 'employee_position'},
                                action: this.forceRerender,
                                visible: this.can(['administrator']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_users',
                        icon: 'mdi-account-group mdi-18px',
                        visible: this.can(['administrator','manager','accountant']),
                        exact: false,
                        active: ['/client', 'invite'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_clients',
                                icon: "mdi-account mdi-18px",
                                to: {name: 'client'},
                                action: this.forceRerender,
                                visible: this.can(['administrator','manager','accountant']),
                                exact: false
                            },
                            {
                                title: 'menu_invitations',
                                icon: "mdi-email-outline mdi-18px",
                                to: {name: 'invite'},
                                action: this.forceRerender,
                                visible: this.can(['administrator','manager']),
                                exact: false
                            },
                        ]
                    },
                    {
                        title: 'menu_companies',
                        icon: 'mdi-home-city mdi-18px',
                        to: {name: "company"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager','accountant']),
                        exact: false
                    },
                    {
                        title: 'menu_storage',
                        icon: 'mdi-file-table-box-outline mdi-18px',
                        to: {name: "storage"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager','accountant']),
                        exact: false
                    },
                    {
                        title: 'menu_about',
                        icon: "mdi-information mdi-18px",
                        to: {name: "about"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_pages',
                        icon: "mdi-page-layout-header-footer mdi-18px",
                        to: {name: "page"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },

                    {
                        title: 'menu_error_correction',
                        header: true,
                        visible: this.can(['administrator']),
                    },
                    {
                        title: 'menu_payments_correction',
                        icon: "mdi-cash mdi-18px",
                        to: {name: "invoice_correction"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_topics_correction',
                        icon: "mdi-clipboard-text-play-outline mdi-18px",
                        to: {name: "topics_correction"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_tracking_correction',
                        icon: "mdi-train-variant mdi-18px",
                        to: {name: "tracking_correction"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },

                    {
                        title: 'menu_handbooks',
                        header: true,
                        visible: this.can(['administrator']),
                    },
                    {
                        title: this.$t('menu_countries'),
                        icon: "mdi-web",
                        to: {name: "country"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_stations'),
                        icon: "mdi-language-ruby-on-rails",
                        to: {name: "station"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_statuses'),
                        icon: "mdi-playlist-check",
                        to: {name: "status"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_sub_statuses'),
                        icon: "mdi-playlist-check",
                        to: {name: "sub_status"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_loading_types'),
                        icon: "mdi-truck-cargo-container",
                        to: {name: "loading_type"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_type_transports'),
                        icon: "mdi-newspaper-variant-outline",
                        to: {name: "type_transport"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },

                    {
                        title: this.$t('menu_type_containers'),
                        icon: "mdi-folder-text-outline",
                        to: {name: "type_container"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_form_properties',
                        icon: "mdi-beaker-outline mdi-18px",
                        to: {name: "form_property"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_invoice_statuses',
                        icon: "mdi-cash-check mdi-18px",
                        to: {name: "invoice_status"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_deal_statuses',
                        icon: "mdi-text-box-check-outline mdi-18px",
                        to: {name: "deal_status"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_task_statuses',
                        icon: "mdi-text-box-check-outline mdi-18px",
                        to: {name: "task_status"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_funnel_statuses',
                        icon: "mdi-text-box-check-outline mdi-18px",
                        to: {name: "funnel_status"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_deal_types'),
                        icon: "mdi-order-alphabetical-ascending mdi-18px",
                        to: {name: "deal_type"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    // {
                    //     title: this.$t('menu_document_types'),
                    //     icon: "mdi-order-alphabetical-ascending mdi-18px",
                    //     to: {name: "document_type"},
                    //     action: this.forceRerender,
                    //     visible: this.can(['administrator']),
                    //     exact: false
                    // },
                    {
                        title: this.$t('menu_type_vacation'),
                        icon: "mdi-shore mdi-18px",
                        to: {name: "type_vacation"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_type_activity'),
                        icon: "mdi-calendar-blank-outline mdi-18px",
                        to: {name: "type_activity"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_attraction_channel'),
                        icon: "mdi-zip-box-outline mdi-18px",
                        to: {name: "attraction_channel"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_task_type'),
                        icon: "mdi-text-box-check-outline mdi-18px",
                        to: {name: "task_type"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_case_type'),
                        icon: "mdi-text-box-check-outline mdi-18px",
                        to: {name: "case_type"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_case_status'),
                        icon: "mdi-text-box-check-outline mdi-18px",
                        to: {name: "case_status"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_currencies'),
                        icon: "mdi-currency-usd mdi-18px",
                        to: {name: "currency"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_sexes',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "sex"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    }


                ]
            ]
        }
    }
}
</script>
