<template>
    <div class="resizable-table__wrap" ref="table-wrap">
        <table
            class="resizable-table" ref="table"
            :style="{ width: widthSum + 'px' }"
        >
            <slot name="header" :props="localColumns">
                <thead>
                <tr>
                    <th v-if="showSelect" style="width: 56px;">
                        <div class="resizable-table__cell">
                            <slot name="header-select">
                                <v-checkbox
                                    class="ma-0"
                                    v-model="allSelected"
                                    @change="selectAll($event)"
                                    :ripple="false"
                                    color="primary"
                                    hide-details
                                ></v-checkbox>
                            </slot>
                        </div>
                    </th>
                    <th
                        v-for="(column, index) in localColumns"
                        :key="index"
                        :style="colWidths[index] ? {
                                minWidth: widthToCss(colWidths[index]),
                                maxWidth: widthToCss(colWidths[index]),
                                width: widthToCss(colWidths[index])
                            } : {}"
                    >
                        <div
                            class="resizable-table__cell"
                            draggable="true"
                            @dragstart="onDragStart($event, index)"
                            @dragover.prevent
                            @drop="onDrop($event, index)"
                        >
                            <slot :name="'header-' + column.prop" :props="localColumns" :column="column">
                                <span class="column-drag-handle">☰</span>
                                {{ column.label }}
                                <v-icon
                                    x-small
                                    v-if="column.sortable"
                                    @click="sortItems(column.prop)"
                                    :class="{
                                        'mdi-rotate-180': (sortBy === column.prop && sortDir),
                                        'primary--text': sortBy === column.prop
                                    }"
                                >
                                    mdi-arrow-up
                                </v-icon>
                            </slot>
                        </div>
                        <div class="resize-handle" @mousedown="startResize($event, index)"></div>
                    </th>
                </tr>
                </thead>
            </slot>
            <tbody>
            <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
                <td v-if="showSelect">
                    <div class="resizable-table__cell">
                        <slot name="select" :item="row" :index="rowIndex">
                            <v-checkbox
                                class="ma-0"
                                hide-details
                                v-model="selectedItems"
                                multiple
                                :value="row"
                                :ripple="false"
                                @change="onSelect"
                            ></v-checkbox>
                        </slot>
                    </div>
                </td>
                <td v-for="(column, colIndex) in localColumns" :key="colIndex">
                    <div class="resizable-table__cell">
                        <slot :name="column.prop" :item="row" :index="rowIndex">
                            {{ row[column.prop] }}
                        </slot>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "ResizableTable",

    props: {
        columns: {
            type: Array,
            required: true
        },

        rows: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        },

        sortBy: {
            type: String,
            default: ''
        },

        sortDir: {
            type: Boolean,
            default: false
        },

        showSelect: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        selectedItems: {
            handler() {
                this.allSelected = this.selectedItems.length === this.rows.length;
            },
            deep: true
        },

        columns: {
            handler(newColumns) {
                if(this.rows.length > 0) {
                    let activeColumns = newColumns.filter((item) => {
                        return item.active === true;
                    }).sort((a, b) => a.sort - b.sort);
                    this.$set(this, 'localColumns', activeColumns);
                    this.colWidths = this.getColWidths(activeColumns);
                }
            },
            deep: true
        },

        rows: {
            handler() {
                if(this.rows.length > 0) {
                    let activeColumns = this.columns.filter((item) => {
                        return item.active === true;
                    }).sort((a, b) => a.sort - b.sort);
                    this.$set(this, 'localColumns', activeColumns);
                    this.colWidths = this.getColWidths(activeColumns);
                }
            }
        },

        localColumns: {
            handler() {
                // this.$emit('columnsChanged', this.localColumns);
            },
            deep: true
        }
    },

    data() {
        return {
            dragging: false,
            resizeIndex: null,
            startX: 0,
            startWidth: 0,
            sum: 0,
            colWidths: [],
            allSelected: false,
            selectedItems: [],
            localColumns: [],
            dragIndex: null
        };
    },

    computed: {
        widthSum() {
            return Object.values(this.colWidths).reduce((sum, width) => sum + width, 0);
        }
    },

    methods: {
        startResize(event, index) {
            this.dragging = true;
            this.resizeIndex = index;
            this.startX = event.pageX;
            this.startWidth = this.colWidths[index] ? this.colWidths[index] : this.getColWidth(index + 1);

            window.addEventListener('mousemove', this.resizeColumn);
            window.addEventListener('mouseup', this.stopResize);
        },

        resizeColumn(event) {
            if (this.dragging) {
                const deltaX = event.pageX - this.startX;
                this.$set(this.colWidths, this.resizeIndex, Math.max(30, this.startWidth + deltaX));
            }
        },

        stopResize() {
            this.dragging = false;
            window.removeEventListener('mousemove', this.resizeColumn);
            window.removeEventListener('mouseup', this.stopResize);

            let cols = this.localColumns.map((field, index) => {
                field.width = this.getColumnWithInPercent(this.getColWidth(index));
                return field;
            });

            this.$emit('columnResized', cols);
        },

        widthToCss(width) {
            if (typeof width === 'number') {
                return `${width}px`;
            }

            if (typeof width === 'string' && (width.endsWith('px') || width.endsWith('%'))) {
                return width;
            }

            if (typeof width === 'string' && !isNaN(parseInt(width))) {
                return `${parseInt(width)}px`;
            }

            return '';
        },

        getColWidth(index) {
            let th = this.$refs.table.querySelectorAll(`th`)[index];

            if (th) {
                return th.offsetWidth - 2; // -2 для учета границы
            }

            return 0;
        },

        sortItems(props) {
            this.$emit('sort', props);
        },

        selectAll(selected) {
            if (selected) {
                this.selectedItems = [...this.rows];
            } else {
                this.selectedItems = [];
            }

            this.onSelect();
        },

        onSelect() {
            this.$emit('select', this.selectedItems);
        },

        onDragStart(event, index) {
            this.dragIndex = index;
            event.dataTransfer.effectAllowed = 'move';
        },

        onDrop(event, dropIndex) {
            const draggedColumn = this.localColumns[this.dragIndex];
            const draggedColumnWidth = this.colWidths[this.dragIndex];

            this.localColumns.splice(this.dragIndex, 1);
            this.localColumns.splice(dropIndex, 0, draggedColumn);

            this.localColumns.map((item, index) => {
                item.sort = index;
                return item;
            });
            this.$set(this, 'localColumns', this.localColumns);

            this.colWidths.splice(this.dragIndex, 1);
            this.colWidths.splice(dropIndex, 0, draggedColumnWidth);

            this.dragIndex = null;
            this.$emit('columnMoved', this.localColumns);
        },

        getColWidths(columns) {
            let totalWidth = 100;
            let cnt = columns.length;
            let minWidth = 100;

            for (let i in columns) {
                let col = columns[i];
                if (col.width && col.width !== 'auto') {
                    totalWidth -= col.width;
                    cnt--;
                }
            }

            if(totalWidth < 0) {
                totalWidth = 100;
                cnt = columns.length;
            }

            if(cnt > 0) {
                minWidth = Math.round(totalWidth / cnt);

                if(this.getColumnWithFromPercent(minWidth) < 100) {
                    minWidth = this.getColumnWithInPercent(100);
                }
            }

            let colsWidth = [];

            for (let i in columns) {
                let col = columns[i];
                let w = 0;
                if (col.width && col.width !== 'auto') {
                    w = this.getColumnWithFromPercent(parseInt(col.width));
                } else {
                    w = this.getColumnWithFromPercent(minWidth);
                }
                colsWidth[i] = w;
            }

            return colsWidth;
        },

        getColumnWithFromPercent(percent) {
            let tableWidth = this.$refs['table-wrap'].offsetWidth;
            return tableWidth * percent / 100;
        },

        getColumnWithInPercent(width) {
            let tableWidth = this.$refs['table-wrap'].offsetWidth;
            return Math.round(width * 100 / tableWidth);
        }
    },

    mounted() {
    },

    beforeDestroy() {
        window.removeEventListener('mousemove', this.resizeColumn);
        window.removeEventListener('mouseup', this.stopResize);
    },
};
</script>

<style lang="scss">
.resizable-table__no-data {
    text-align: center;
    color: var(--text-color-light);
}

.resizable-table__wrap {
    overflow-x: auto;
    width: 100%;
    scrollbar-width: auto;
}

.resizable-table {
    width: auto;
    table-layout: fixed;
    border-collapse: collapse;
    overflow: hidden;
    border-top: 0;

    tbody {
        & > tr {
            border-bottom: 1px solid #EFEFEF;

            &:first-child {
                & > td:first-child {
                    border-radius: $border-radius-root 0 0 0;
                }

                & > td:last-child {
                    border-radius: 0 $border-radius-root 0 0;
                }
            }

            &:last-child {
                border-bottom: none;

                & > td:first-child {
                    border-radius: 0 0 0 $border-radius-root;
                }

                & > td:last-child {
                    border-radius: 0 0 $border-radius-root 0;
                }
            }
        }

        td {
            background-color: #fff;
        }
    }

    th, td {
        position: relative;
        border-left: 0;
        border-right: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
        text-align: left;

        &:hover {
            .resize-handle {
                opacity: 1;
            }
        }
    }

    th {
        border: 0;
        color: #969696;
        vertical-align: bottom;

        .resizable-table__cell {
            text-transform: uppercase;
            padding-top: 0;
            padding-bottom: 8px;
            font-size: 12px;
            margin: 5px;
            display: flex;
        }
    }
}

.resizable-table__cell {
    padding: $grid-gutter / 2 $grid-gutter
}

.resize-handle {
    position: absolute;
    right: 0;
    top: 4px;
    bottom: 4px;
    width: 4px;
    cursor: ew-resize;
    background-color: transparent;

    user-select: none;
    opacity: 0;

    &:after {
        content: "";
        background-color: #E7E7E7;
        position: absolute;
        right: 0;
        width: 1px;
        bottom: 0;
        top: 0;
    }
}

.column-drag-handle {
    cursor: move;
    padding-right: 8px;
}
</style>
