import { initializeApp } from "firebase/app"
import { getMessaging, onMessage } from "firebase/messaging"
//import { onBackgroundMessage } from 'firebase/messaging/sw'
//import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig)
const messaging = getMessaging(app)

onMessage(messaging, (payload) => {
    //console.log('Message received.3 ', payload);
    const data = {...payload.notification, ...payload.data};
    const notificationTitle = data.title;
    let notificationOptions = {
        body: data.body,
        requireInteraction: true,
        data
    }
    if (data.icon) {
        notificationOptions.icon = data.icon
    }

    if (data.image) {
        notificationOptions.image = data.image
    }
    if (data.click_action) {
        notificationOptions.click_action = data.click_action
    }
    const message = new Notification(notificationTitle, notificationOptions);
    message.addEventListener('click', function() {
        window.location.href = data.link;
    });
});
