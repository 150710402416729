<template>
    <div v-if="loaded">
        <app-top-menu
            :show-app-bar="showAppBar"
            :key="sidebarKey" :drawer="drawer" @nav-toggle="navToggle"></app-top-menu>
        <v-main ref="main" >
            <app-sidebar  :drawer="drawer" @nav-toggle="navToggle"></app-sidebar>
            <transition name="fade" mode="out-in" >
                <router-view :key="componentKey" id="scrolling-techniques-7" max-height="400" ></router-view>
            </transition>
        </v-main>
        <v-footer app style="background-color: #F5F5F5">
            <v-row>
                <v-col cols="12" sm="2">
                    <v-btn block disabled>
                        <v-icon>
                            mdi-phone
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="6" style="position: relative">
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                    <ValidationProvider ref="search_text" rules="min:1|max:255"
                                        v-slot="{ errors, valid }">
                        <v-text-field
                            v-model="search_text" type="text" :error-messages="errors"
                            :disabled="userBlock || loading"
                            color="primary"
                            hide-details
                            solo
                            flat
                            dense
                            class="ma-0 pa-0 my-0 py-0 "
                            clearable
                            height="35"
                            :label="$t('search')"
                            ref="search_text_field"
                            @keypress.enter="search()"
                            autocomplete="off"
                        ></v-text-field>
                    </ValidationProvider>
                    </ValidationObserver>

                    <v-menu

                        content-class="menu_fixed"
                        bottom
                        v-if="showSearch"
                        v-model="showSearch"
                        min-width="49%"
                        max-width="49%"
                        :position-x="SearchX"

                        :close-on-content-click="false"

                        nudge-bottom="true"
                    >
                        <v-card  max-height="400"
                                 min-height="400">

                                <v-tabs

                                    slider-size="0"
                                    background-color="#eeeeee"
                                >
                                    <v-tab
                                        v-for="(item, i) in header_tabs"
                                        :key="i"
                                        style="text-transform:none !important;"
                                        @click="search_type = item.slug"
                                    >
                                            {{$t(item.name)}} <span v-if="item.count > 0" class="pl-1" style="color: #B3B3B3">{{item.count}}</span>



                                    </v-tab>
                                </v-tabs>



                            <v-tabs-items v-model="tabs">
                                <v-tab-item>
                                    <v-card flat>
                                        <v-list three-line>
                                            <template v-for="(item, index) in filterSearchItems">


                                                <v-list-item :key="index + item.title" style="min-height: auto !important;">
                                                    <v-list-item-icon class="mx-0 pr-3 mt-3">
                                                        <v-icon color="grey lighten-1" size="20" v-if="item.type === 'client'">
                                                            mdi-account-multiple-outline
                                                        </v-icon>
                                                        <v-icon color="grey lighten-1" size="20" v-else-if="item.type === 'task'">
                                                            mdi-calendar-check
                                                        </v-icon>
                                                        <v-icon color="grey lighten-1" size="20" v-else-if="item.type === 'deal'">
                                                            mdi-briefcase-variant-outline
                                                        </v-icon>
                                                    </v-list-item-icon>

                                                    <v-list-item-content>
                                                        <v-list-item-subtitle class="pb-1">
                                                            <v-btn v-if="item.type === 'client'" text plain :to="'/uved/' + item.uuid + '/show'"  class="cursor-pointer text--primary button_link search_link"  >
                                                            <span class="text--primary" >{{$t('uved.self')}}: </span>
                                                                {{ item.title }}
                                                            </v-btn>
                                                            <v-btn v-else-if="item.type === 'task'" text plain :to="'/task/' + item.uuid + '/show'"  class="cursor-pointer text--primary button_link search_link"  >
                                                                <span class="text--primary" >{{$t('task.self')}}: </span>
                                                                {{ item.title }}
                                                            </v-btn>
                                                           <v-btn v-else-if="item.type === 'deal'" text plain :to="'/deal/' + item.uuid + '/show'"  class="cursor-pointer text--primary button_link search_link"  >
                                                                <span class="text--primary" >{{$t('deal.self')}}: </span>
                                                               {{ item.title }}
                                                            </v-btn>
                                                        </v-list-item-subtitle>
                                                        <template v-for="(search, key) in item.search">
                                                            <div :key="key + item.type" class="v-list-item__subtitle pb-1">
                                                                <span class="text--primary" v-if="search.type === 'title'">{{$t('name')}}: </span>
                                                                <span class="text--primary" v-else-if="search.type === 'description'">{{$t('description')}}: </span>
                                                                <span class="text--primary" v-else-if="search.type === 'file'">{{$t('files')}}: </span>
                                                                <span class="text--primary" v-else-if="search.type === 'number'">{{ item.type === 'task' ? $t('number_task') : $t('number_deal')}}: </span>
                                                                <span class="text--primary" v-else-if="search.type === 'email'">{{$t('email')}}: </span>
                                                                <span class="text--primary" v-else-if="search.type === 'phone'">{{$t('phone')}}: </span>
                                                                <span v-html="search.text"></span>
                                                            </div>
                                                        </template>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-card>
                                    <v-overlay :value="loading" z-index="10" color="grey lighten-3">
                                        <v-progress-circular color="grey darken-1"
                                            indeterminate
                                            size="32"
                                        ></v-progress-circular>
                                    </v-overlay>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card>



                    </v-menu>
                </v-col>
                <v-col cols="12" sm="4" class="pt-5">
                    <v-badge v-if="false"
                        overlap
                        bordered
                        color="green"
                        content="99+"
                    >
                        <v-icon >
                            mdi-bell-outline
                        </v-icon>
                    </v-badge>

                </v-col>
            </v-row>
        </v-footer>
        <v-dialog
            v-model="dialogImportFileError"
            v-if="dialogImportFileError"
            width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ $t('errors_found_uploaded_file') }}</span>
                </v-card-title>
                <v-card-text v-for="(error, i) in textImportFileError" :key="i" v-html="error">
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogImportFileError = false"
                    >
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="information"
            :vertical="false"
            :timeout="-1"
            color="primary"
        >
           <span @click="informationOpen(information_date);information = false" class="cursor_pointer">
               {{ information_date.message }}
           </span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="information = false"
                >
                    {{$t('close')}}
                </v-btn>
                <v-btn
                    text
                    v-bind="attrs"
                    @click="informationOpen(information_date); information = false"
                >

                    {{$t('open')}}
                </v-btn>

            </template>
        </v-snackbar>

        <v-dialog
            transition="dialog-bottom-transition"
            persistent
            max-width="700"
            v-model="dialogSocket"
            v-if="dialogSocket"
        >
            <v-card>
                <v-toolbar class="dialog_toolbar" elevation="0">
                    <div class="ma-auto ml-0">
                        <span>{{ dialog.title }}</span>
                    </div>
                    <div>
                        <v-icon @click="dialogSocket = false" :title="$t('close')">mdi-close</v-icon>
                    </div>
                </v-toolbar>
                <v-card-text >
                    <v-row >
                        <v-col class="py-0 pt-5" cols="12">
                            <v-list
                                two-line
                                subheader
                                v-if="dialog.data_text !== undefined"
                            >
                                <v-list-item v-for="(item, index)  in dialog.data_text" :key="'dialog_'+item.value+'_'+index">
                                    <v-list-item-avatar>
                                        <v-icon color="primary">
                                            mdi-card-text
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.title"></v-list-item-title>
                                        <v-list-item-subtitle v-text="item.text"></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <div  v-if="dialog.data_text_show !== undefined">{{dialog.data_text_show}}</div>
                        </v-col>

                    </v-row>

                </v-card-text>
                <v-card-actions class="pt-5">
                    <v-spacer></v-spacer>
                    <v-btn v-if="dialog.data.route === 'refresh'" text color="primary" @click="dialogSocket = false;$router.go(0)">
                        {{ $t('refresh') }}
                    </v-btn>
                    <v-btn v-else text color="primary" @click="goToLink(dialog.data)">
                        {{ $t('open') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>



</template>



<script>

import Vue from 'vue'
import mixin from '../../plugins/mixin'
import {ValidationObserver, ValidationProvider} from 'vee-validate'

Vue.mixin(mixin)

import Echo from "laravel-echo";
import {getMessaging, getToken} from "firebase/messaging";
import {mapGetters, mapActions} from "vuex"
import AppSidebar from "../../components/AppSidebar"
import AppTopMenu from "../../components/AppTopMenu"

export default {
    name: "MainLayout",
    components: {
        AppSidebar,
        AppTopMenu,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        source: String,
        push: Object
    },
    provide: function () {
        return {
            forceRerender: this.forceRerender,
            forceRerenderSidebar: this.forceRerenderSidebar,
            setUserData: this.setUserData,
        }
    },
    data() {
        return {
            loading: false,
            loaded: false,
            drawer: false,
            componentKey: 0,
            sidebarKey: 0,
            friend: null,
            dialogImportFileError: false,
            textImportFileError: null,
            showAppBar: true,
            information: false,
            information_date: {},
            dialogSocket: false,
            dialog: {},
            showSearch: false,
            SearchX: 0,
            SearchY: 0,
            tabs: 0,
            header_tabs: [
                {
                    name: 'all_results',
                    slug: '',
                    count: 0,
                },
                {
                    name: 'uveds',
                    slug: 'client',
                    count: 0,
                },
                {
                    name: 'tasks',
                    slug: 'task',
                    count: 0,
                },
                {
                    name: 'deals',
                    slug: 'deal',
                    count: 0,
                }
            ],
            searchItems: [],
            search_text: null,
            search_type: ''
        }
    },
    computed: {
        ...mapGetters(['userBlock','lang', 'language', 'defaultAvatar', 'userAvatar', 'userName', 'userLogin','userEmployeePosition', 'userInitials', 'theme']),
        filterSearchItems(type = '') {
            if (this.search_type === '') {
                return this.searchItems; // Возвращает весь массив, если тип не передан
            } else {
                return this.searchItems.filter(item => item.type === this.search_type); // Фильтрует массив по указанному типу
            }
        }

    },
    created() {
        window.addEventListener("scroll", this.handleScroll);

    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    async mounted() {
        this.appName = process.env.VUE_APP_NAME
        this.drawer = this.$vuetify.breakpoint.mdAndUp
        //await this.setTheme()
        await this.$auth
            .load()
            .then(() => {
                if (this.$auth.check() && this.$auth.user() && this.$auth.user().settings) {
                    this.setSettings(this.$auth.user().settings)
                    this.setUserName(this.$auth.user().name)
                    this.setUserInitials(this.$auth.user().name)
                    this.setUserPosition(this.$auth.user().position)
                    this.setEmployeePosition(this.$auth.user().employee_position)
                    this.setUserAvatar((this.$auth.user().photo ? this.$auth.user().photo : this.defaultAvatar))
                    this.setCountUnreadNotifications((this.$auth.user().countUnreadNotifications ? this.$auth.user().countUnreadNotifications : this.countUnreadNotifications))
                    this.listenChannelWebsocket()
                    //this.listenChannelFCM()
                }
                this.loaded = true
            })
        this.setLang()
    },
    methods: {
        ...mapActions(['setCountUnreadNotifications','setSettings', 'setLanguage', 'setUserName', 'setUserInitials', 'setUserPosition','setEmployeePosition', 'setUserAvatar']),


        countItemsByType(type) {
            return this.searchItems.filter(item => item.type === type).length;
        },


        async search() {
            this.searchItems = [];
            // Обновляем значения count для каждого элемента в массиве header_tabs
            this.header_tabs.forEach(tab => {
                tab.count = this.countItemsByType(tab.slug);
            });
            this.header_tabs[0].count = this.searchItems.length;
            this.loading = true
            const textareaRect = this.$refs.search_text_field.$el.getBoundingClientRect();
            this.SearchX = textareaRect.left;
            this.SearchY = textareaRect.top - 405;
            this.showSearch = true
            let params = {}
            params.search = this.search_text
            await this.$http
                .get("admin/search", {
                    params: params,
                })
                .then(res => {
                    this.searchItems = res.body.data
                    // Обновляем значения count для каждого элемента в массиве header_tabs
                    this.header_tabs.forEach(tab => {
                        tab.count = this.countItemsByType(tab.slug);
                    });
                    this.header_tabs[0].count = this.searchItems.length;
                })
                .catch(err => {
                    this.searchItems = []
                })
                .finally(end => {
                    this.loading= false
                })
        },

        goToLink(data){
            this.dialogSocket = false;
            let route = {
                "name" : data.route,
                "params" : data.params,
            }
            this.$router.push(route);
        },
        informationOpen(item){



            if(item.data && item.data.type && item.data.route ){

                if((item.data.type === 'profile' || item.data.type === 'invoice' || item.data.type === 'tracking_updates' || item.data.type === 'invitation_accepted') && item.data.route !== ''){

                    this.$router.push({
                        name: item.data.route,
                    })
                }
                else if(item.data.type ===  'task.show' || item.data.type ===  'deal.show'){

                    this.$router.push({
                        name: item.data.route,
                        params: item.data.params
                    })
                }
                else if(item.data.type ===  'discuss_chat' || item.data.type ===  'discuss_chat'){

                    this.$router.push({
                        name: item.data.route,
                        params: item.data.params
                    })
                }
            }
        },
        handleScroll() {
            if (window.scrollY > 0) {
                this.showAppBar = false;
            } else {
                this.showAppBar = true;
            }
        },
        setLang2() {
            if (this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language
                this.setLanguage(this.$auth.user().language)
            }
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
        },
        getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        },
        async setLang() {
            let language = this.getCookie('language')
            if (language == null && this.$auth.check() && this.$auth.user() && this.$auth.user().language) {
                document.documentElement.lang = this.$auth.user().language

            }
            else {
                document.documentElement.lang = language
            }
            this.$vuetify.lang.current = document.documentElement.lang
            this.$i18n.locale = document.documentElement.lang
            this.setLanguage(document.documentElement.lang)
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
            this.forceRerender()
        },
        setTheme(themeSelect) {
            let localTheme = localStorage.getItem('themeDark')
            localTheme = localTheme === 'true' ? true : (localTheme === 'false' ? false : null)
            const mediaTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
            this.$vuetify.theme.dark = (themeSelect !== undefined ? themeSelect : (localTheme !== null ? localTheme : mediaTheme))
            localStorage.setItem('themeDark', this.$vuetify.theme.dark)
            document.documentElement.setAttribute('class', (this.$vuetify.theme.dark ? 'dark' : 'light'))
        },
        forceRerender: function () {
            this.componentKey += 1
        },
        forceRerenderSidebar: function () {
            this.sidebarKey += 1
        },
        navToggle() {
            this.drawer = !this.drawer
        },
        checkNotification() {
            var _this = this
            if (!("Notification" in window)) {
                console.log("This browser does not support desktop notification");
            } else if (Notification.permission === "granted") {
                console.log("Notification.permission granted");
                // If it's okay let's create a notification
                //var notification = new Notification("Hi there 1!")
                this.listenChannelFCM()
            } else if (Notification.permission !== 'denied' || Notification.permission === "default") {
                Notification.requestPermission(function (permission) {
                    console.log("User accepts, let's create a notification");
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        //var notification = new Notification("Hi there 2!")
                        _this.listenChannelFCM()
                    }
                })
            }
        },
        listenChannelFCM() {
            const messaging = getMessaging();
            getToken(messaging, {vapidKey: process.env.VUE_APP_FCM_VAPID_KEY})
                .then((token) => {
                    if (token) {
                        // Send the token to your server and update the UI if necessary
                        //console.log('Send the token to your server ', token);
                        console.log('Send the token to your server');
                        this.saveNotificationToken(token)
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
        },
        saveNotificationToken(token) {
            const params = {
                token: token,
                type: 'browser'
            }
            this.$http
                .post("admin/device", params)
                .then((res) => {
                    console.log('Successfully saved notification token!')
                })
                .catch((err) => {
                    console.log('Error: could not save notification token')
                })
        },
        listenChannelWebsocket() {
            window.Echo = new Echo({
                broadcaster: "pusher",
                key: process.env.VUE_APP_PUSHER_APP_KEY,
                cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                disableStats: true,
                encrypted: true,
                enabledTransports: ["wss", "ws"],
                //wsHost: window.location.hostname,
                wsHost: this.$pusherServer,
                authorizer: (channel, options) => {
                    return {
                        authorize: (socketId, callback) => {
                            this.$http.post(`https://${this.$pusherServer}/api/broadcasting/auth`, {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                                .then(response => {
                                    callback(false, response.data)
                                })
                                .catch(error => {
                                    callback(true, error)
                                })
                        }
                    }
                }
            })

            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .listen('.Event.Admin.Messages', (event) => {

                    if (event.data.type.indexOf('file_xml') !== -1) {
                        this.$toastr.success(`${event.title} ${event.message}`)
                    }
                    else if (event.data.type.indexOf('notify') !== -1) {
                        this.$toastr.success(`${event.title} ${event.message}`)
                    }
                    else if (event.data.type.indexOf('error_xml_file') !== -1) {
                        this.$toastr.error(`${event.title} ${event.message}`)
                        this.dialogImportFileError = true
                        this.textImportFileError = event.data.errors;
                    }
                    else if (event.data.socket.indexOf('information') !== -1) {
                        // console.log('====================================');
                        // console.log(event);
                        // console.log('====================================');
                        this.information = true
                        this.information_date = event
                    }

                })
                .listen('.Event.Admin.Dialog', (event) => {

                    if (event.data.dialog.indexOf('case_send') !== -1) {
                        this.dialog = {"title": event.title, "text": event.message, "data": event.data, "data_text": event.text}
                        this.dialogSocket = true;
                    }
                    else if (event.data.dialog.indexOf('unlock_admin') !== -1) {
                        this.dialog = {"title": event.title, "text": event.message, "data": event.data, "data_text_show": event.message}
                        this.dialogSocket = true;
                    }

                })
            /*
            .notification((notify) => {
                //console.log('MainLayout notify')
                console.log(notify)
                if (notify.type.indexOf('broadcast.message') !== -1) {
                    this.$toastr.success(`${notify.title} ${notify.message}`)
                }
            })
           */
        },
        setUserData() {
            this.setSettings(this.$auth.user().settings)
            this.setUserName(this.$auth.user().name)
            this.setUserInitials(this.$auth.user().name)
            this.setLanguage(this.$auth.user().language)
            this.setUserAvatar(this.$auth.user().avatar)
            this.setEmployeePosition(this.$auth.user().employee_position)
            this.setCountUnreadNotifications(this.$auth.user().countUnreadNotifications)

            //this.checkNotification()
            this.listenChannelWebsocket()

        },
    }
}
</script>
