<script>
export default {
    name: "PageLayout"
}
</script>

<template>
    <div class="page-layout">
        <div class="page-layout__main" :class="{'page-layout__main--fill-width': !$slots.side}">
            <slot></slot>
        </div>
        <div class="page-layout__side" v-if="!!$slots.side">
            <slot name="side"></slot>
        </div>
    </div>
</template>

<style scoped lang="scss">
$sideWidth: 30%;
$sideMaxWidth: 400px;
$mainWidth: 100% - $sideWidth;

.page-layout {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: $grid-gutter;

    .page-layout__main {
        flex-grow: 1;
        width: 100%;
        margin-bottom: $grid-gutter;
    }

    .page-layout__side {
        width: 100%;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        flex-wrap: nowrap;
        padding: 36px;

        .page-layout__main {
            max-width: 100%;
            margin-bottom: 0;
            width: $mainWidth;
            padding-right: 21px;

            &--fill-width {
                width: 100%;
                padding-right: 0;
            }
        }

        .page-layout__side {
            width: $sideWidth;
            max-width: $sideMaxWidth;
            padding-left: 21px;
        }
    }
}
</style>
