import "roboto-fontface/css/roboto/roboto-fontface.css"
import "cropperjs/dist/cropper.css"
import "element-ui/lib/theme-chalk/index.css";
import '@mdi/font/css/materialdesignicons.css'
import '../styles/custom.css'

import Vue from "vue";
import Vuetify from "vuetify/lib";

import en from 'vuetify/lib/locale/en'
import ru from 'vuetify/lib/locale/ru'
import kk from '../locales/vendor/vuetify/kk'

import StoragesIcon from '../components/swg/StoragesIcon.vue'
import EditIcon from '../components/swg/EditIcon.vue'
import SettingsIcon from '../components/swg/SettingsIcon.vue'
import DeleteIcon from '../components/swg/DeleteIcon.vue'
import RightIcon from '../components/swg/RightIcon.vue'
import LeftIcon from '../components/swg/LeftIcon.vue'
import CloseIcon from '../components/swg/CloseIcon.vue'
import LockIcon from '../components/swg/LockIcon.vue'
import LockOpenIcon from '../components/swg/LockOpenIcon.vue'


Vue.use(Vuetify);


export default new Vuetify({
    options: {
        customProperties: true,
    },
    /*
    breakpoint: {
        scrollBarWidth: 16,
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 0,
            sm: 470,
            md: 600,
            lg: 800,
            xl: 1280,
            xxl: 1920
        },
    },
    */
    theme: {
        themes: {
            light: {
                primary: '#2B95EB',
                secondary: '#56C30E', // Вторичный цвет
                text: '#454545',
                /*
                primary: '#1976D2',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                */
            },
            dark: {
                primary: '#2B95EB',
            },
        },
    },
    lang: {
        locales: {en, ru, kk},
        current: process.env.VUE_APP_I18N_LOCALE || process.env.VUE_APP_I18N_FALLBACK_LOCALE
    },
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4
        values: {
            StoragesIcon: {
                component: StoragesIcon
            },
            editIcon: {
                component: EditIcon
            },
            settingsIcon: {
                component: SettingsIcon
            },
            deleteIcon: {
                component: DeleteIcon
            },
            rightIcon: {
                component: RightIcon
            },
            leftIcon: {
                component: LeftIcon
            },
            closeIcon: {
                component: CloseIcon
            },
            lockIcon: {
                component: LockIcon
            },
            lockOpenIcon: {
                component: LockOpenIcon
            },
        }
    },
    defaults: {
        VBtn: {
            color: 'primary',
            variant: 'text',
        },
        VSelect: {
            outlined: true,
            solo: true,
            flat: true,
            dense: true,
        }
    }
});
