import _ from 'lodash'
import router from '@/plugins/router'


/**
 *
 @param a
 @param b
 @param ignoreQueryParams
 @param ignoreParams
 @returns {false|boolean|*}
 */
export function isSameRouteWithQueryParams(a, b, {
    ignoreQueryParams = false,
    ignoreParams = false,
} = {}) {
    if (!a || !b) return false;

    if(a.path && b.path) {
        if (a.path !== b.path) return false;
    } else if(a.name && b.name) {
        if (a.name !== b.name) return false;
    } else {
        return false;
    }

    if(!ignoreParams && a.params && b.params) {
        if (!_.isEqual(a.params, b.params)) return false;
    }

    if (!ignoreQueryParams && !_.isEqual(a.query ?? {}, b.query ?? {})) return false;

    return true;
}

export function isRouteChildrenOf(route, parentRoute)
{
    const a = router.resolve(route).href;
    const b = router.resolve(parentRoute).href;

    return b.startsWith(a);
}

export function buildTree(elements, parentId = 0, idKeyName = 'id', parentIdKeyName = 'parent_id', childrenKeyName = 'children') {
    let branch = [];

    elements.forEach(element => {
        if (element[parentIdKeyName] === parentId) {
            let children = buildTree(elements, element[idKeyName], idKeyName, parentIdKeyName, childrenKeyName);
            if (children.length) {
                element[childrenKeyName] = children;
            }
            branch.push(element);

            elements = elements.filter(e => e[idKeyName] !== element[idKeyName]);
        }
    });

    return branch;
}

export function buildListArrayFromTreeArray(arTree, childrenKeyName = 'children') {
    let arResult = [];

    arTree.forEach(arItem => {
        let tmpItem = { ...arItem };
        delete tmpItem[childrenKeyName];

        arResult.push(tmpItem);
        if (arItem[childrenKeyName]) {
            arResult.push(...buildListArrayFromTreeArray(arItem[childrenKeyName]));
        }
    });

    return arResult;
}
