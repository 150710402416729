<script>
import moment from "moment";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
    name: "User",

    components: {UserAvatar},

    props: {
        item: {
            type: Object,
            required: true
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        vacationFrom() {
            if(!this.item.vacation)
                return null;

            return moment(this.item.vacation.from_date).format('DD.MM.YYYY');
        },
        vacationTo() {
            if(!this.item.vacation)
                return null;

            return moment(this.item.vacation.to_date).format('DD.MM.YYYY');
        },
    },

    methods: {
        onClick() {
            if(this.disabled)
                return;

            this.$emit('click')
        }
    }
}
</script>

<template>
    <div class="usr" @click="onClick">
        <UserAvatar class="usr__avatar" :img="item.photo" :alt="item.name" />

        <div v-if="item.vacation" class="usr__vacation">
            <v-icon>mdi-umbrella-beach</v-icon>
            <div class="usr__vacation-info">
                <b>{{item.vacation.type}}</b><br>
                {{ this.vacationFrom }} - {{ this.vacationTo }}
                <b>{{ $t('columns.admin.vacation.replacing') }}</b>:<br>{{item.vacation.replacing.name}}
            </div>
        </div>

        <div class="usr__info">
            <div class="usr__name">
                {{item.name}}
            </div>
            <div class="usr__position">
                <slot name="position">
                    {{formatPhoneNumber(item.phone)}}
                </slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.usr {
    display: flex;
    align-items: center;
    color: $application-color;
    position: relative;
    text-decoration: none;
}

.usr__avatar {
    margin-right: $grid-gutter / 2;
    position: relative;
}

.usr__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    width: 100%;
}

.usr__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.usr__position {
    color: #b3b3b3;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.usr__vacation-info {
    width: 200px;
    background-color: #fff;
    position: absolute;
    z-index: 10;
    padding: $grid-gutter / 2;
    display: none;
    text-align: left;
    color: var(--text-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

$vacation-icon-size: 16px;
.usr__vacation {
    display: inline-block;
    position: absolute;
    left: calc(42px - #{$vacation-icon-size} + 2px);
    bottom: -2px;
    border-radius: 100%;
    background-color: orange;
    width: $vacation-icon-size;
    height: $vacation-icon-size;
    border: 1px solid #fff;

    &:hover {
        .usr__vacation-info {
            display: block;
        }
    }

    .v-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 100%;
        color: #fff;
    }
}

.usr__avatar {
    //overflow: visible;
}
</style>
