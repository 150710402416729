var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"table-wrap",staticClass:"resizable-table__wrap"},[_c('table',{ref:"table",staticClass:"resizable-table",style:({ width: _vm.widthSum + 'px' })},[_vm._t("header",function(){return [_c('thead',[_c('tr',[(_vm.showSelect)?_c('th',{staticStyle:{"width":"56px"}},[_c('div',{staticClass:"resizable-table__cell"},[_vm._t("header-select",function(){return [_c('v-checkbox',{staticClass:"ma-0",attrs:{"ripple":false,"color":"primary","hide-details":""},on:{"change":function($event){return _vm.selectAll($event)}},model:{value:(_vm.allSelected),callback:function ($$v) {_vm.allSelected=$$v},expression:"allSelected"}})]})],2)]):_vm._e(),_vm._l((_vm.localColumns),function(column,index){return _c('th',{key:index,style:(_vm.colWidths[index] ? {
                            minWidth: _vm.widthToCss(_vm.colWidths[index]),
                            maxWidth: _vm.widthToCss(_vm.colWidths[index]),
                            width: _vm.widthToCss(_vm.colWidths[index])
                        } : {})},[_c('div',{staticClass:"resizable-table__cell",attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.onDragStart($event, index)},"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.onDrop($event, index)}}},[_vm._t('header-' + column.prop,function(){return [_c('span',{staticClass:"column-drag-handle"},[_vm._v("☰")]),_vm._v(" "+_vm._s(column.label)+" "),(column.sortable)?_c('v-icon',{class:{
                                    'mdi-rotate-180': (_vm.sortBy === column.prop && _vm.sortDir),
                                    'primary--text': _vm.sortBy === column.prop
                                },attrs:{"x-small":""},on:{"click":function($event){return _vm.sortItems(column.prop)}}},[_vm._v(" mdi-arrow-up ")]):_vm._e()]},{"props":_vm.localColumns,"column":column})],2),_c('div',{staticClass:"resize-handle",on:{"mousedown":function($event){return _vm.startResize($event, index)}}})])})],2)])]},{"props":_vm.localColumns}),_c('tbody',_vm._l((_vm.rows),function(row,rowIndex){return _c('tr',{key:rowIndex},[(_vm.showSelect)?_c('td',[_c('div',{staticClass:"resizable-table__cell"},[_vm._t("select",function(){return [_c('v-checkbox',{staticClass:"ma-0",attrs:{"hide-details":"","multiple":"","value":row,"ripple":false},on:{"change":_vm.onSelect},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})]},{"item":row,"index":rowIndex})],2)]):_vm._e(),_vm._l((_vm.localColumns),function(column,colIndex){return _c('td',{key:colIndex},[_c('div',{staticClass:"resizable-table__cell"},[_vm._t(column.prop,function(){return [_vm._v(" "+_vm._s(row[column.prop])+" ")]},{"item":row,"index":rowIndex})],2)])})],2)}),0)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }