import nav from '@/plugins/nav';

const getItems = (Vue) => {
    let items = nav;

    items = getVisibility(items, Vue)
    items = getActivity(items, Vue)

    return items;
}

const getVisibility = (items, Vue) => {
    items.forEach((item, index) => {
        items[index].visible = Vue.can(item.permissions);

        if(item.items) {
            items[index].items = getVisibility(item.items, Vue);
        }
    });

    return items;
}

const getActivity = (items, Vue) => {
    items.forEach((item, index) => {
        items[index].active = Vue.$route.path === Vue.$router.resolve(item.route).resolved.path;
        let hasActiveChild = false;

        if(item.items) {
            items[index].items = getActivity(item.items, Vue);
            hasActiveChild = item.items.some((child) => child.active);
        }

        items[index].active = items[index].active || hasActiveChild;
    });

    return items;
}

const breadcrumbs = (items) => {
    let breadCrumbs = [];
    let breadcrumb = items.find((item) => item.active);

    if(breadcrumb) {
        breadCrumbs.push(breadcrumb);

        if(breadcrumb.items) {
            breadCrumbs = breadCrumbs.concat(breadcrumbs(breadcrumb.items));
        }
    }

    return breadCrumbs;
}

const NavMixin = {
    data() {
        return {
            navItems: getItems(this)
        }
    },

    created() {},

    watch: {
        '$route'() {
            // this.navItems = getItems(this);
        }
    },

    methods: {
        breadcrumbs() {
            return breadcrumbs(this.navItems);
        }
    }
};

export default NavMixin;
