<script>
export default {
    name: "UserAvatar",

    props: {
        img: {
            type: String,
            required: false,
            default: null
        },
        alt: {
            type: String,
            required: false,
            default: ''
        }
    },

    computed: {
        hasPhoto() {
            return this.img !== null && this.img !== '/img/avatar.png';
        }
    }
}
</script>

<template>
    <v-avatar class="avatar" :class="{'border_blue': !hasPhoto}" :size="$vuetify.breakpoint.smAndUp ? 40 : 30">
        <template v-if="hasPhoto">
            <img :src="img" :alt="alt">
        </template>
        <template v-else>
            <v-icon color="primary" size="20">
                ${{'settingsIcon'}}
            </v-icon>
        </template>
    </v-avatar>
</template>

<style lang="scss">
.v-avatar {
    border-radius: 8px;
    background-color: #fff;
}
</style>
