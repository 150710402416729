<template>
    <v-main   :key="componentKey">
        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </v-main>
</template>
<script>

export default {
    name: "single-page-layout",
    provide: function () {
        return {
            forceRerender: this.forceRerender,
        }
    },
    data() {
        return {
            loaded: false,
            componentKey: 0,
        }
    },
    async mounted() {
        this.setLang()
    },
    methods: {
        setLang() {
            let language = this.getCookie('language')

            if (language) {
                document.documentElement.lang = language
                this.$vuetify.lang.current = document.documentElement.lang
                this.$i18n.locale = document.documentElement.lang
            }
            if (typeof this.$i18n.locale !== 'undefined') {
                this.$i18n.locale = document.documentElement.lang
            }
            this.$moment.locale(this.$i18n.locale)
            if (typeof this.$vuetify.lang.current !== 'undefined') {
                this.$vuetify.lang.current = document.documentElement.lang
            }
            this.forceRerender()
        },
        getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        },
        forceRerender: function () {
            this.componentKey += 1
        },
    }
}
</script>
